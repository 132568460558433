import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDarkModeManager } from '../state/user/hooks'
import Settings from "../components/Settings"
import Menu from '../components/Menu'
import svgImage from "../assets/images/svg-path.svg"
import svgImageMobile from "../assets/images/svg-path-mobile.svg"
import { ResizeObserver } from 'resize-observer';
export const BodyWrapper = styled.div`
position: relative;
overflow:visible;
max-width: 420px;
width: 100%;
// opacity: 0.6;
// background green;
background: ${({ theme }) => theme.bg1};
box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
border-radius: 30px;
padding: 13px;
// padding: 1rem;
// margin: 64px auto;
  // position: relative;
  // max-width: 420px;
  // width: 100%;
  // background: ${({ theme }) => theme.bg1};
  // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  //   0px 24px 32px rgba(0, 0, 0, 0.01);
  // border-radius: 30px;
  // padding: 1rem;
  ${({ theme }) => theme.isDark && `
  background:none;
  border:3px solid ${theme.darkBlue};
  box-shadow:inset 0px 0px 200px rgba(0,0,0,1);
  `}
`
const AppContainer = styled.div`
position:relative;
max-width: 420px;
width:100%;


${({ theme }) => theme.isDark && `
// background:url(${svgImage});
background-size:100%;
background-repeat:no-repeat;
// margin-top:-80px;
padding:30px 60px 80px 60px;
display:flex;
flex-direction:column;
align-items:center;
-webkit-align-items: center;
 -webkit-box-align: center;
 -ms-flex-align: center;
overflow:visible;
max-width:540px;

@media(max-width:750px){
margin-top:0px;
padding:40px;
padding-bottom:80px;
}
@media(max-width:500px){
  // background-image:url(${svgImageMobile});
}
`}
`
const ControlsWrapper = styled.div`
display:flex;
-webkit-align-items: center;
 -webkit-box-align: center;
 -ms-flex-align: center;
 align-items: center;
 justify-content:flex-end;
 width:100%;
 margin-top:40px;
`
const DotContainer = styled.div`
position:absolute;
left:-35px;
top:20%;
z-index:2;
display:flex;
flex-direction:column;
@media(max-width:500px){
  left:-23px;
}

`
const Dot = styled.div`
width:15px;
height:15px;
display:inline-block;
border:2px solid ${({ theme }) => theme.golden};
box-shadow:0px 0px 6px${({ theme }) => theme.golden},inset 0px 0px 4px${({ theme }) => theme.golden} ;

border-radius:50px;
margin-top:10px;
`
const RectangleWrapper = styled.div`
position:absolute;
left:30%;
bottom:-110px;
z-index:2;
display:flex;
flex-direction:column;
align-items:flex-end;
-webkit-align-items: flex-end;
 -webkit-box-align: flex-end;
 -ms-flex-align: flex-end;
`
const Rectangle = styled.div<{ index?: number }>`
width:120px;
height:20px;
display:inline-block;
border:1px solid ${({ theme }) => theme.text2};
box-shadow:0px 0px 4px${({ theme }) => theme.text2},inset 0px 0px 4px${({ theme }) => theme.text2} ;

margin-bottom:20px;
border-radius:5px;
clip-path: polygon(16% 0, 100% 0, 100% 100%, 36% 100%);
${({ index }) => index === 1 && `
// width: 70px; 
clip-path: polygon(56% 0, 100% 0, 100% 100%, 76% 100%);
`} 
@media(max-width:500px){
  width:60px;
//   ${({ index }) => index === 1 && `
// width: 40px; 
// `} 
}
`

const Svg = styled.svg`
filter: 
drop-shadow(0 0px 2px #fff) 
drop-shadow(0 0px 3px cyan)
drop-shadow(0 0px 5px cyan)
drop-shadow(0 0px 5px cyan);
position:absolute;
left:0;
right:0;
bottom:0;

`
const BGImage = ({ parentRef }: { parentRef: any }) => {
  const [path, setPath] = useState<string>("");
  const svg = useRef<any>();
  const removePX = (value: string) => Number(value.replace("px", ""));
  const observer = new ResizeObserver(drawImage)
  useEffect(() => {

  }, [])
  useEffect(() => {
    window.addEventListener("resize", drawImage);

    observer.observe(parentRef.current)
    return () => {
      window.removeEventListener("resize", drawImage);
      if (parentRef.current)
        observer.unobserve(parentRef.current)

    }
  }, [parentRef, svg])
  function drawImage() {
    if (parentRef !== null && parentRef.current && svg.current) {
      var path = "";
      const div: HTMLElement = parentRef.current,
        svgEl: any = svg.current,
        diff = 14;
      const height = div.offsetHeight,
        width = div.offsetWidth,
        style = window.getComputedStyle(div),
        center = (removePX(style.paddingLeft) - diff) / 2,
        left = removePX(style.paddingLeft) / 2.4 - diff,
        right = width - removePX(style.paddingRight) / 2.4 + diff,
        top = diff,
        bottom = height - diff;
      svgEl.style.width = width.toString();
      svgEl.style.height = height.toString();
      const xGrid = 15,
        yGrid = 17,
        GridWidthX = (right - left) / xGrid,
        GridWidthY = (bottom - top) / yGrid;
      let x = right - center * 2 - diff,
        y = top;
      path += `M ${x} ${y}`;
      x = right - center;
      y = top;
      path += `Q ${x} ${y} ${x} ${y + GridWidthY}`;

      path += `L ${x} ${GridWidthY * 10}`;
      x = right;
      path += `Q ${x} ${GridWidthY * 11} ${x} ${GridWidthY * 12}`;
      path += `L ${x} ${GridWidthY * (yGrid - 1)}`;
      y = bottom;
      path += `Q ${x} ${y} ${x - center - diff} ${y}`;
      x = width / 2 + diff;
      path += `H ${x}`;
      y = GridWidthY * (yGrid - 2);
      path += `C ${x - GridWidthX * 2} ${bottom} ${x - GridWidthX * 1} ${y} ${x - GridWidthX * 4
        } ${y}`;
      x = left + center * 2 + diff;
      path += `H ${x}`;
      x = left + center;
      path += `Q ${x} ${y} ${x} ${y - GridWidthY * 2}`;
      x = left;
      y = y - GridWidthY * 2;
      path += `Q ${x} ${y} ${x} ${y - GridWidthY}`;
      y = GridWidthY * 4;
      path += `L ${x} ${y}`;
      y = GridWidthY * 3;
      path += `Q ${x} ${y} ${left + center} ${y}`;
      x = left + center;
      y = top;
      path += `Q ${x} ${y} ${left + center * 2 + diff} ${y}`;
      x = right - center * 2 - diff;
      path += `H ${x}`;

      setPath(path);
    }
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" ref={svg}>
      <path d={path} stroke="#dff" strokeWidth="2px" fill="none"></path>
    </Svg>

  )
}
/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  const isDark = useDarkModeManager()[0];
  const containerRef = useRef<HTMLDivElement>(null);
  return <>
    <AppContainer ref={containerRef}>
      {
        isDark &&
        <BGImage parentRef={containerRef} />
      }
      <BodyWrapper >
        {
          isDark && <>
            <DotContainer>
              <Dot />
              <Dot />
              <Dot />
            </DotContainer>
            <RectangleWrapper>
              <Rectangle />
              <Rectangle index={1} />
            </RectangleWrapper>
          </>
        }
        {children}
      </BodyWrapper>

      {
        isDark &&
        <ControlsWrapper>

          <Settings />
          <Menu />
        </ControlsWrapper>
      }
    </AppContainer>
  </>
}